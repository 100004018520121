import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyC10sGa4T4nDk4HfqLrKnAUUd6WdfOGMK4",
    authDomain: "fontcert-project.firebaseapp.com",
    projectId: "fontcert-project",
    storageBucket: "fontcert-project.appspot.com",
    messagingSenderId: "108295136161",
    appId: "1:108295136161:web:3161d61b0694b435daa1e0",
    measurementId: "G-XRF2H4R7ZY"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
