// app/features/AuthHelper.ts

import {
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  UserProfile,
} from "firebase/auth";
import { auth, provider } from "./firebaseConfig";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { login, logout, storeCurrentUrl, updateMembership } from "./authSlice";
import { store } from "~/store";
axios.defaults.withCredentials = true;

export const signInWithGoogle = async (dispatch: any) => {
  const currentUrl = window.location.href;
  dispatch(storeCurrentUrl(currentUrl));
  try {
    const result = await signInWithPopup(auth, provider);
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential?.accessToken; // Add null check for credential

    getIdTokenAndValidate(dispatch); // Pass dispatch to firebaseAuthValidate
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const signInWithEmailPassword = async (
  dispatch: any,
  email: string,
  password: string
) => {
  const currentUrl = window.location.href;
  dispatch(storeCurrentUrl(currentUrl));
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    getIdTokenAndValidate(dispatch);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const registerWithEmailAndPassword = async (
  email: string,
  password: string,
  displayName: string
) => {
  try {
    // Create user with email and password
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    // Create a profile object with display name
    const userProfile: UserProfile = {
      displayName: displayName,
    };

    // Update user's profile with the profile object
    await updateProfile(userCredential.user, userProfile);

    return userCredential.user; // Return the user object
  } catch (error) {
    throw error; // Throw any errors for handling in the UI
  }
};

const getIdTokenAndValidate = (dispatch: any) => {
  const currentUser = auth.currentUser;
  if (currentUser) {
    currentUser.getIdToken(/* forceRefresh */ true).then(
      function (idToken: string) {
        firebaseAuthValidate(dispatch, idToken);
      }.bind(this)
    );
  }
};

const firebaseAuthValidate = (dispatch: any, idToken: string) => {
  let url = "https://backend.fontcert.com/firebase_auth_validate/";
  let debug = false;
  if (debug) {
    url = "http://localhost:5510/firebase_auth_validate/";
  }
  const bodyFormData = new FormData();
  bodyFormData.append("id_token", idToken);

  axios({
    method: "post",
    url: url,
    data: bodyFormData,
  })
    .then((response) => {
      const user = response.data;
      proceedToNext(dispatch, user); // Pass dispatch to proceedToNext
    })
    .catch((error) => {
      // Handle error
    });
};

function proceedToNext(dispatch: any, user: any) {
  dispatch(login(user));
  const currentUrl = useSelector((state: any) => state.auth.currentUrl);
  window.location.href = currentUrl;
}

// Check if the user is authenticated
export function isAuthenticated() {
  return useSelector((state: any) => state.auth.isAuthenticated);
}

// Check for the current user
export function currentUser() {
  return useSelector((state: any) => state.auth.user);
}

// check for pro user
export function isProUser() {
  return useSelector((state: any) => state.auth.membership);
}

function isClientLoggedIn() {
  const state = store.getState();
  return state.auth.isAuthenticated;
}

// get configurations from backend
export function checkConfiguration(dispatch: any) {
  let url = "https://backend.fontcert.com/get_configurations/";
  let debug = false;
  if (debug) {
    url = "http://localhost:5510/get_configurations/";
  }
  axios({
    method: "get",
    url: url,
  })
    .then((response) => {
      const data = response.data;
      if (data.profile.is_authenticated === true) {
        // User is authenticated in backend
          dispatch(updateMembership(data.profile.membership)); // Update membership state
      } else {
        // User is not authenticated in backend, logout the user
        if (isClientLoggedIn()) {
          // If user is authenticated in client-side but not in backend, let's logout the user
          dispatch(logout());
          window.location.href = "/"; // Redirect to home page
        }
      }
    })
    .catch((error) => {
      // Handle error
      throw error;
    });
}

export function logoutUser() {  
  let url = "https://backend.fontcert.com/logout_user/";
  let debug = false;
  if (debug) {
    url = "http://localhost:5510/logout_user/";
  }
  axios({
    method: "get",
    url: url,
  })
    .then((response) => {
      const data = response.data;
    })
    .catch((error) => {
      // Handle error
      throw error
    });
}